import { Grid, Button, InputBase, Paper, IconButton, Typography, FormControl, MenuItem, Select, InputLabel } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../../../../../features/admin_panel/adminPanelSlice';
import { useStyles } from './styles';
import { useState } from 'react';


export default function SearchPanel({ setSearch, filter, setFilter, setChooseAdDialog}) {
    const [sorting, setSorting] = useState('');
    const userInfo = useSelector(selectUserInfo)
    const classes = useStyles()

    return (
        <Grid container className={classes.root} mb={2} p={1} alignItems='center' justifyContent="space-evenly">
            <Grid item className={classes.searchBlock} xs={12} sm={8} md={8} lg={7} align='center' p={0}>
                <Paper component="form" >
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon color='primary'/>
                    </IconButton>
                    <InputBase sx={{ width: { xs: '85%', lg: '90%' } }} onChange={(e) => setSearch(e.target.value)}
                        size="small" className="standard-basic" placeholder="Search Project" />
                </Paper>
            </Grid>

            <Grid container item p={0} xs={4} sm={3} md={2} lg={1.5} alignItems='center'>
                <Grid item xs={2} align="center">
                    <Typography className="vertical_line">|</Typography>
                </Grid>

                <Grid item xs={8} align="center">
                    <Button color='primary' onClick={() => setFilter(!filter)}><FilterAltIcon color='primary' fontSize="small" />Filter</Button>
                </Grid>

                <Grid item xs={2} align="center">
                    <Typography >|</Typography>
                </Grid>
            </Grid>

            <Grid item xs={4} sm={3} md={2} lg={1.5} align='center'>
                <FormControl fullWidth size='small' >
                    <InputLabel id="demo-simple-select-label">Sort By</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={sorting}
                        label="Sort By"
                        onChange={(e) => setSorting(e.target.value)}
                    >
                        <MenuItem value={1}>Ad Name (A to Z)</MenuItem>
                        <MenuItem value={2}>Ad Name (Z to A)</MenuItem>
                        <MenuItem value={3}>Price (Low to High)</MenuItem>
                        <MenuItem value={4}>Price (High to Low)</MenuItem>

                    </Select>
                </FormControl>
            </Grid>
            {!userInfo.have_access_like_investor &&
                <Grid item sx={{ mr: { xs: 1, lg: 0 } }}>
                    <IconButton item className={classes.addBtn} onClick={() => setChooseAdDialog(true)}
                    >
                        <AddIcon />
                    </IconButton>
                </Grid>
            }

        </Grid>
    )
}