import { makeStyles } from "@mui/styles";
import { tokens } from '../../../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: {
            '& .MuiRating-iconFilled': {
                color: colors.primary.DEFAULT
            },
            '& .MuiRating-iconEmpty': {
                color: colors.gray[50],
                stroke: colors.secondary[50]
            },
            '& .MuiButtonBase-root': {
                background: colors.secondary.DEFAULT,
                textTransform: 'none'
            }
        },
        cardBlock: {
            background: `${colors.black.DEFAULT} !important`,
            border: `1px solid ${colors.primary.DEFAULT}`,
            borderRadius: '10px'
        },
        infoBlock: {
            '& .MuiSvgIcon-root': {
                fill: colors.primary.DEFAULT
            }
        },
        borderBottom: {
            borderBottom: `1px solid ${colors.primary.DEFAULT}`,
        },
        lineBlock: {
            borderTop: `1px solid ${colors.primary.DEFAULT}`
        },
        statisticBlock: {
            borderBottom: `1px solid ${colors.primary.DEFAULT}`
        }
    })

})