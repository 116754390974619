import * as React from 'react';
import { Box, Stepper, Step, Grid, StepLabel } from '@mui/material'
import { useForm, FormProvider } from "react-hook-form";
import General from './tabs/General/General';
import Details from './tabs/Details/Details';
import Location from './tabs/Location/Location'
import Overview from './tabs/Overview/Overview';
import Publishing from './tabs/Publishing/Publishing';
import makeApiRequest from '../../../../api/api';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectProject, updateGeneralProjectInfo, updateDetailsProjectInfo, updateLocationProjectInfo, addProjectMedia,
    addProjectDrawings, addProjectCertificates, fetchSingleProject, setProjectId, selectSelectedTariffId, setTariffId
} from '../../../../features/projects/projectsSlice';
import { useParams } from 'react-router-dom';
import Loader from '../../../components/Loader/Loader';

import './add_project.css'
import './tabs/tabs.css'

const steps = ['General', 'Features', 'Location', 'Overview', 'Publishing'];


export default function AddSingleProject({ purpose = '' }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()
    const [disabled, setDisabled] = React.useState(false)
    const methods = useForm({ mode: 'onChange', disabled })
    const project = useSelector((state) => selectProject(state, purpose))
    const tariff = useSelector(selectSelectedTariffId)

    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [success, setSuccess] = React.useState(false)
    const [loader, setLoader] = React.useState(true)

    const handleStep = (step) => () => {
        step === 4 && methods.trigger()
        setActiveStep(step);
        const newCompleted = {};
        for (let i = 0; i < step; i++) {
            newCompleted[i] = true
        }
        setCompleted(newCompleted);
    };

    React.useEffect(() => {
        if (purpose === 'edit' && project) {
            methods.setValue('location.address', project?.location.address)
        }
    }, [project])

    React.useEffect(() => {
        if (purpose === 'add' && !tariff) {
            navigate('/admin_panel/projects/my_projects/')
        }
        else if (purpose === 'edit' && !project) {
            dispatch(fetchSingleProject(id))
            dispatch(setProjectId({ project_id: id }))
        } else if (purpose !== 'edit') {
            dispatch(setProjectId({ project_id: 0 }))
        }
        setLoader(false)
    }, [])

    const handleSave = (data) => {
        if (data?.details?.construction_materials === "") {
            data['details']['construction_materials'] = []
        }

        if (purpose === 'add') {
            data.general.tariff = tariff
            makeApiRequest('/api/projects/', 'POST', data.general).then((res) => {
                makeApiRequest(`/api/${res.data.estate_category}/`, 'POST', { ...data.details, project: res.data.id, ...data.features }).then(() => {
                    makeApiRequest('/api/location/', 'POST', { ...data.location, project: res.data.id }).then(() => {
                        // Media 
                        makeApiRequest('/api/project_media/', 'POST', { ...data.media, project: res.data.id }, false, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
                            makeApiRequest('/api/project_drawings/', 'POST', { ...data.drawings, project: res.data.id }, false, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
                                makeApiRequest('/api/project_certificates/', 'POST', { ...data.certificates, project: res.data.id }, false, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
                                    setTimeout(() => window.location.href = '/admin_panel/projects/my_projects', 2000)
                                    dispatch(setTariffId({ tariffId: null }))
                                    setSuccess(true)
                                }).catch(() => { makeApiRequest(`/api/projects/${res.data.id}/`, 'DELETE') })
                            }).catch(() => { makeApiRequest(`/api/projects/${res.data.id}/`, 'DELETE') })
                        }).catch(() => { makeApiRequest(`/api/projects/${res.data.id}/`, 'DELETE') })
                    }).catch(() => { makeApiRequest(`/api/projects/${res.data.id}/`, 'DELETE') })
                }).catch(() => { makeApiRequest(`/api/projects/${res.data.id}/`, 'DELETE') })
            })
        }
        else if (purpose === 'edit') {
            if (methods.formState.dirtyFields?.general) {
                dispatch(updateGeneralProjectInfo({ projectId: project.id, data: data.general }))
            }

            if (methods.formState.dirtyFields?.details || methods.formState.dirtyFields?.features) {
                dispatch(updateDetailsProjectInfo({ type_of_estate: data.general.estate_category, projectId: project.id, detailsId: project.details.id, data: { ...data.details, ...data.features } }))
            }

            if (methods.formState.dirtyFields?.location) {
                makeApiRequest(`/api/location/${project.location.id}/`, 'PATCH', data.location).then((res) => {
                    dispatch(updateLocationProjectInfo({ 'id': project.id, data: res.data }))
                })
            }

            if (methods.formState.dirtyFields?.media) {
                makeApiRequest(`/api/project_media/`, 'POST', { ...data.media, project: project.id }, false, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {
                    dispatch(addProjectMedia({ 'id': project.id, data: res.data }))
                })
            }
            if (methods.formState.dirtyFields?.drawings) {
                makeApiRequest('/api/project_drawings/', 'POST', { ...data.drawings, project: project.id }, false, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {
                    dispatch(addProjectDrawings({ 'id': project.id, data: res.data }))
                })
            }
            if (methods.formState.dirtyFields?.certificates) {
                makeApiRequest('/api/project_certificates/', 'POST', { ...data.certificates, project: project.id }, false, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {
                    dispatch(addProjectCertificates({ 'id': project.id, data: res.data }))
                })
            }
            methods.resetField('media', { defaultValue: project?.media })
            methods.resetField('drawings', { defaultValue: project?.drawings })
            methods.resetField('certificates', { defaultValue: project?.certificates })
            setSuccess(true)
            setTimeout(() => setSuccess(false), 2000)
        }
    }

    return (
        loader
            ? <Loader />
            : <Grid container m={0} p={2} alignItems="flex-start" className='wrap_container'>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(async (data) => {
                        setDisabled(true)
                        await handleSave(data)
                        setDisabled(false)
                    })}>
                        <Box sx={{ width: '100%' }}>
                            <Stepper nonLinear activeStep={activeStep} alternativeLabel className='stepper_box'>
                                {steps.map((label, index) => (
                                    <Step key={label} className={index in completed ? 'step_completed' : 'step_not_completed'} >
                                        <StepLabel color="inherit" onClick={handleStep(index)}>
                                            {label}
                                            <span className={activeStep === index || index in completed ? "circle_complete" : 'circle'}></span>
                                        </StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            {((purpose !== 'edit') || (purpose === 'edit' && project)) &&
                                <React.Fragment>
                                    <General activeStep={activeStep} setActiveStep={setActiveStep} setCompleted={setCompleted} project={project} purpose={purpose} />
                                    <Details activeStep={activeStep} setActiveStep={setActiveStep} setCompleted={setCompleted} project={project} />
                                    <Location activeStep={activeStep} setActiveStep={setActiveStep} setCompleted={setCompleted} project={project} purpose={purpose} />
                                    <Overview activeStep={activeStep} setActiveStep={setActiveStep} setCompleted={setCompleted} project={project} purpose={purpose} />
                                    <Publishing activeStep={activeStep} setActiveStep={setActiveStep} setCompleted={setCompleted} success={success} disabled={disabled} purpose={purpose} />
                                </React.Fragment>
                            }
                        </Box>
                    </form>
                </FormProvider>
            </Grid>
    )
}