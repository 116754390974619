import { Grid, Paper, Button, Typography, ClickAwayListener, Tooltip } from "@mui/material"
import { useStyles } from "./styles"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import UserTable from "./components/UserTable";
import { useState, useEffect } from "react";
import RootTable from "./components/RootTable";
import makeApiRequest from "../../../../../api/api";

export default function PromoCode({ userInfo }) {
    const classes = useStyles()
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(NaN)

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const toClipboard = () => {
        navigator.clipboard.writeText(userInfo?.promo_code)
        setOpen(true)

        setTimeout(() => {
            handleTooltipClose()
        }, 800)
    }

    useEffect(() => {
        const fetchData = async () => {
            await makeApiRequest('/api/promo_code/').then((res) => {
                setData(res.data)
            })
        }
        fetchData()

    }, [])

    return (
        <Grid container p={1} alignItems="flex-start" className={classes.root}>
            <Grid container item p={0} pt={2} pb={2} xs={12} sx={{ mb: { xs: 2, lg: 0 } }}
                component={Paper} className={classes.cardBlock} justifyContent='space-evenly' alignItems={'start'}>
                <Grid container p={1} alignItems={'center'}>
                    <Grid container item p={1} xs={userInfo?.is_superuser ? 12 : 6}>
                        <Grid item align={'start'}>
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                                <div>
                                    <Tooltip
                                        arrow
                                        onClose={handleTooltipClose}
                                        open={open}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title="copied"
                                        slotProps={{
                                            popper: {
                                                disablePortal: true,
                                            },
                                        }}
                                    >
                                        <Button variant="outlined" endIcon={<ContentCopyIcon />} onClick={toClipboard}>{userInfo?.promo_code}</Button>
                                    </Tooltip>
                                </div>
                            </ClickAwayListener>
                        </Grid>

                        <Grid item ml={1}>
                            <Typography color={'primary'}>5% sale</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item p={1} xs={6} justifyContent={'end'}>
                        {data.extra?.total &&
                            <Typography>Total: {data.extra?.total} $</Typography>
                        }
                    </Grid>
                </Grid>

                {data &&
                    <Grid container p={1}>
                        {!userInfo?.is_superuser ? <UserTable data={data} /> : <RootTable data={data} />}
                    </Grid>
                }
            </Grid>
        </Grid>
    )
}