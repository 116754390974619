import { makeStyles } from "@mui/styles";
import { tokens } from '../../../../theme'
import { useTheme } from '@mui/material'
import svg from '../assets/images/no_products.svg'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: {
            background: colors.black.DEFAULT,
            borderRadius: '10px',
            height: 'inherit',
            overflow: 'auto'
        },
        noProjects: {
            backgroundImage: `url(${svg})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
        }
    })
})