import * as React from "react";
import {
    Grid, TextField, Typography, Button, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Alert, Link
} from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useForm } from "react-hook-form";
import FormError from '../../../AdminPanel/components/FormError/FormError'
import axios from "axios";
import { useNavigate } from 'react-router-dom'
import { useState } from "react";
import { useStyles } from "../styles";


export default function SignIn() {
    const classes = useStyles()
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = React.useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange' })
    const [notification, setNotification] = useState(false)


    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleSave = (form_data) => {
        axios.post(`${process.env.REACT_APP_API_DEV}/auth/login/`, {
            email: form_data.email,
            password: form_data.password
        }).then((response) => {
            localStorage.setItem('access_token', response.data.tokens.access)
            localStorage.setItem('refresh_token', response.data.tokens.refresh)
            localStorage.setItem('user', response.data.user === " " ? 'Your Account' : response.data.user)
            navigate('/admin_panel/projects/my_projects')
        }).catch((err) => {
            setNotification(true)
        })
    }

    return (
        <form onSubmit={handleSubmit(handleSave)} className={classes.form}>

            <Grid item xs={12} mb={1}>
                <Typography variant="body1" align='center' sx={{ 'fontSize': '25px', 'fontWeight': '600' }}>
                    Sign In
                </Typography>
            </Grid>

            <Grid item xs={12} mb={2}>
                <Typography variant="body1" align='center' color={'#AAAAAA'}
                    sx={{ 'fontSize': '13px', 'fontWeight': '400' }}>
                    Enter your credentials to sign in
                </Typography>
            </Grid>

            <Grid item xs={12} mt={2}>
                <TextField
                    fullWidth
                    size="small"
                    {...register('email', {
                        required: 'Email is required field!',
                        pattern: {
                            message: 'Please enter valid email!',
                            value: /^((([0-9A-Za-z]{1}[-0-9A-z\.]{1,}[0-9A-Za-z]{1})|([0-9А-Яа-я]{1}[-0-9А-я\.]{1,}[0-9А-Яа-я]{1}))@([-0-9A-Za-z]{1,}\.){1,2}[-A-Za-z]{2,})$/
                        }
                    })}
                    id="filled-required"
                    label="Email address"
                    variant="outlined"
                />
                <FormError error={errors?.email} errors={errors} name={'email'} />
            </Grid>

            <Grid item xs={12} mt={2}>
                <FormControl sx={{ m: 0, width: '100%' }} variant="outlined" size="small">
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        {...register('password', {
                            required: 'Password is required field!',
                            // pattern: {
                            //     value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_]).{8,}$/,
                            //     message: 'The password must contain upper and lower case Latin letters, numbers and special character!'
                            // },
                            minLength: {
                                value: 4,
                                message: 'The password must consist of at least 4 characters!'
                            }
                        })}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                    />
                </FormControl>
                <FormError error={errors?.password} errors={errors} name={'password'} />
            </Grid>

            <Grid container p={0}>
                <Grid item>
                    <Link href='/recovery-password'><p className="forgot_password">Forgot password?</p></Link>
                </Grid>
            </Grid>

            {notification &&
                <Grid item xs={12} mt={2}>
                    <Alert severity="error">Login or password is incorrect!</Alert>
                </Grid>
            }

            <Grid item xs={12} mb={2} align="center">
                <Button type="submit" variant="outlined" fullWidth>Sign In</Button>
            </Grid>

            <Grid item xs={12} mt={2}>
                <div className="line"></div>
            </Grid>

            <Grid item xs={12} mt={2} mb={2}>
                <Typography variant="body1" align='center'
                    sx={{ 'fontSize': '15px !important', 'fontWeight': '400' }}>
                    <a href="/sign_up/" className='link_conditions'>Create Account</a>
                </Typography>
            </Grid>

        </form>
    )
}
