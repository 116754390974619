import { makeStyles } from "@mui/styles";
import { tokens } from '../../../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: {
            '& .MuiButtonBase-root': {
                color: colors.white.DEFAULT,
                background: colors.secondary.DEFAULT
            }
        },

        cardBlock: {
            background: `${colors.black.DEFAULT} !important`,
            border: `1px solid ${colors.primary.DEFAULT}`,
            borderRadius: '10px'
        },
    })

})