import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { selectUserInfo } from "../../../../features/admin_panel/adminPanelSlice"
import AllInclusiveRoundedIcon from '@mui/icons-material/AllInclusiveRounded';
import { useStyles } from "./styles"
import React, { useState } from "react"

const TITLES = {
    'add_advert': {
        title: 'Do you approve the publish you want to add?',
        subtitle: '(After this process, your ad will be published on the package you purchased.)'
    }
}

export default function ChoosePackage({ isOpen, setIsOpen, titleKey, successFunc, chosenTariffId, setChosenTariffId }) {
    const classes = useStyles()
    const userInfo = useSelector(selectUserInfo)

    const get_property = (tariff, v) => {
        if (v === 'ad') {
            if (tariff.title === 'unlimited') {
                return `Can Add ∞ Ads (for All Estate Types)`
            }
            return `Can Add ${tariff?.ads_amount}${tariff.extra_ads > 0 ? `+${tariff.extra_ads}` : ''} Ads (for All Estate Types)`
        }
        else {
            if (tariff.title === 'unlimited') {
                return `Highlight - Promotion (∞ Use) Ad`
            }
            return `Highlight - Promotion (${tariff.highlights}${tariff.extra_highlights > 0 ? `+${tariff.extra_highlights}` : ''} Use) Ad`
        }
        
    }

    const get_free_places = () => {
        if (chosenTariffId) {
            let tariff = userInfo.tariffs.find((el) => el.id === chosenTariffId)
            if (tariff?.free_places === 'unlimited' || tariff?.free_places > 0) {
                return true
            }
            else {
                return false
            }
        }
        else {
            return false
        }
    }

    return (
        <React.Fragment>
            <Dialog
                className={classes.root}
                open={isOpen}
                onClose={() => setIsOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="h6">{TITLES[titleKey].title}</Typography>
                    <Typography fontSize={'11px'}>{TITLES[titleKey].subtitle}</Typography>
                </DialogTitle>
                <DialogContent>

                    {userInfo.tariffs.map((tariff, ind) => (
                        <Grid container p={0} className={[classes.tariff, chosenTariffId === tariff.id && classes.chosenTariff].join(' ')}
                            onClick={() => {
                                setChosenTariffId(tariff.id)
                                setChosenTariffId(tariff.id)
                            }}>
                            <Grid container p={0} pb={1} key={ind} alignItems={'baseline'}>
                                <Grid item pl={1} flexGrow={1}>
                                    <Grid container p={1} pb={0}>
                                        <Typography variant="h6">{tariff.title} Plan</Typography>
                                    </Grid>
                                    <Grid item p={1} pt={0}>
                                        <Grid className={classes.line}></Grid>
                                    </Grid>

                                    {['ad', 'highlight'].map((el, index) => (
                                        <Grid container p={0} pl={1} alignItems={'flex-start'} key={index}>
                                            <Grid item >
                                                <CheckRoundedIcon fontSize="6px" color="primary" />
                                            </Grid>
                                            <Grid item >
                                                <Typography fontSize={'11px'}>{get_property(tariff, el)}</Typography>
                                            </Grid>
                                        </Grid>
                                    ))}

                                </Grid>
                                <Grid item p={0} m={1} className={classes.verticalLine} alignSelf={'stretch'}>

                                </Grid>
                                <Grid item flexGrow={1}>
                                    <Grid container p={1}>
                                        <Typography fontSize={'10px'}>Number of ads remaining in your package :</Typography>
                                    </Grid>
                                    <Grid container p={1} alignItems={'flex-start'}>
                                        <Grid item xs={6}>
                                            <Typography fontSize={'10px'}>*You Can Publish</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography color='primary' fontSize={'40px'} fontWeight={700} lineHeight={'.5'}>
                                                {tariff.free_places === 'unlimited' ? <AllInclusiveRoundedIcon fontSize="20px" /> : tariff.free_places}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>

                    ))}

                </DialogContent>
                <DialogActions>
                    <Grid container p={0} justifyContent={'space-evenly'} spacing={1}>
                        <Grid item flexGrow={1} align='center'>
                            <Button variant='contained' onClick={() => setIsOpen(false)}>Decline</Button>
                        </Grid>
                        <Grid item flexGrow={1} align='center'>
                            <Button variant='contained' disabled={!chosenTariffId || !get_free_places()} onClick={() => successFunc()} autoFocus> Transfer</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}