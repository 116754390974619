import { Grid, Button } from '@mui/material'
import NavBar from './components/NavBar/NavBar';
import SideBar from './components/SideBar/SideBar';
import ListAdminPanelProjects from './Projects/ListProject/ListAdminPanelProjects';
import ViewSingleProject from './Projects/ViewProject/ViewSingleProject';
import AddSingleProject from './Projects/AddProject/AddSingleProjectPage';
import Confirmation from './Confirmation/Confirmation';
import MainProfile from './Profile/MainProfile';
import Requests from './Requests/Requests';
import Pricing from './Pricing/Pricing';
import { fetchUserInfo, selectUserInfo, setChosenItem, setChosenCollapseItem, setCollapseItems } from '../../features/admin_panel/adminPanelSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation } from 'react-router-dom';
import './css/admin_panel.css'
import { useStyles } from './css/mainPageStyles';
import Loader from '../components/Loader/Loader';


const DROP_DATA = {
    'projects': ['my_projects', 'confirmation_projects'],
    'requests': [],
    'pricing': [],
    'profile': ['profile', 'personal_details', 'security_settings', 'packages',
        'general_settings', 'wallet']
}


export default function MainPage({ purpose }) {
    const [loader, setLoader] = useState(true)
    const width = useMediaQuery('(max-width:900px)')
    const [sw_status, swSet] = useState(width ? false : true)
    const userInfo = useSelector(selectUserInfo)
    const dispatch = useDispatch()
    const classes = useStyles()
    const { pathname } = useLocation()

    useEffect(() => {
        dispatch(fetchUserInfo()).then((e) => {
            e.meta.requestStatus !== "rejected" && setLoader(false)
        })
        window.createLemonSqueezy();
    }, [])

    useEffect(() => {
        let path = pathname.replace('/admin_panel/', '').split('/')
        path[0] in DROP_DATA && dispatch(setChosenItem({ chosenItem: path[0] }))
        if (path[1]) {
            dispatch(setChosenCollapseItem({ chosenCollapseItem: path[1] }))
            dispatch(setCollapseItems({ collapseItemKey: path[0], collapseItemValue: true }))
        }
        else {
            dispatch(setChosenCollapseItem({ chosenCollapseItem: '' }))
        }
    }, [pathname])

    return (
        loader ?
            <Loader />
            :
            <Grid container p={0} className={classes.root} flexDirection={'column'} flexWrap={'nowrap'}>
                <Grid container item p={0} alignSelf={'normal'} >
                    <NavBar funSw={swSet} isOpen={sw_status} />
                </Grid>
                <Grid container className="container_admin_panel_1_layer" p={0} alignItems="flex-start">
                    <Grid container item className={sw_status ? "container_menu_bar" : "container_menu_bar menu_bar_hide"}
                        xs={7} sm={4.5} md={sw_status ? 2.7 : .9} lg={sw_status ? 1.9 : .7} xl={sw_status ? 1.7 : .6}
                        p={0} sx={{ 'height': '-webkit-fill-available' }}>
                        <SideBar isOpen={sw_status} userInfo={userInfo} />
                    </Grid>
                    <Grid container item p={1} pl={0} xs={12} sm={12} className='container_admin_panel_2_layer'
                        md={sw_status ? 9.3 : 11} lg={sw_status ? 10.1 : 11.1} xl={sw_status ? 10.3 : 11.4} >
                        <Grid container className='container_admin_panel_3_layer' item alignItems="flex-start" p={1.5}>
                            {purpose === 'list_projects' && <ListAdminPanelProjects />}
                            {purpose === 'view_project' && <ViewSingleProject purpose={'view'} />}
                            {purpose === 'add_project' && <AddSingleProject purpose={'add'} />}
                            {purpose === 'edit_project' && <AddSingleProject purpose={'edit'} />}
                            {purpose === 'confirmation' && <Confirmation />}
                            {purpose === 'profile' && <MainProfile />}
                            {purpose === 'requests' && <Requests />}
                            {purpose === 'price_list' && <Pricing />}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    )
}