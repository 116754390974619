import { makeStyles } from "@mui/styles";
import { tokens } from '../../../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: {
            borderRadius: '16px',
            background: colors.white.DEFAULT,
            '& .MuiInputBase-root': {
                backgroundColor: `${colors.white.DEFAULT} !important`,
                color: colors.black.DEFAULT
            }
        },
        
    })
})