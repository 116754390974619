import Pagination from '@mui/material/Pagination';
import { useSelector, useDispatch } from 'react-redux';
import { selectPageCount, fetchProjects, selectCurrentPage } from '../../../../../features/projects/projectsSlice';

export default function PaginatorAdvert({setLoader}) {
    const page_count = useSelector(selectPageCount)
    const current_page = useSelector(selectCurrentPage)

    const dispatch = useDispatch()

    const handleChange = (e, p) => {
        setLoader(true)
        dispatch(fetchProjects({page_size: p})).then(() => setLoader(false))
    }
    return (
        page_count > 1 &&
            <Pagination count={page_count} page={current_page} color='primary' onChange={handleChange}/>
    )
}