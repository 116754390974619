import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from './pages/WebSite/HomePage/Home';
import RegistrationPage from "./pages/WebSite/Registration/Registration";
import SuccessReg from './pages/WebSite/Registration/SuccessRegistration/success_registration';

import OverView from "./pages/WebSite/AboutPage/OverView/Overview";
import Mission from "./pages/WebSite/AboutPage/Mission/Mission";
import OurTeam from "./pages/WebSite/AboutPage/OurTeam/OurTeam";

import Blog from './pages/WebSite/HomePage/Blog/Blog';
import Contact from './pages/WebSite/ContactPage/Contact';
import Projects from './pages/WebSite/ProjectsPage/Projects';
import ProjectInfo from './pages/WebSite/ProjectsPage/ProjectDetail/ProjectInfo';

import EmailConfirm from "./pages/WebSite/EmailConfirm/EmailConfirm";

import NotFound from "./pages/WebSite/NotFound/NotFound";
import MainPage from "./pages/AdminPanel/MainPage";
import { pdfjs } from 'react-pdf';

import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from '@mui/material'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PROFILE_TABS = ['profile', 'personal_details', 'security_settings', 'packages', 'general_settings', 'promo_code', 'wallet']

export default function App() {
  const [theme, colorMode] = useMode()

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route element={<HomePage />} path="/" />
            <Route element={<RegistrationPage purpose="sign_up" />} path="/sign_up" />
            <Route element={<RegistrationPage purpose="sign_in" />} path="/sign_in" />
            <Route element={<RegistrationPage purpose="recovery_password" />} path="recovery-password" />
            <Route element={<RegistrationPage purpose="recovery_password_from_email" />} path="recovery-password/:uid/:token" />

            <Route element={<SuccessReg />} path="/success_registration" />

            <Route element={<OverView />} path="about/overview" />
            <Route element={<Mission />} path="about/mission" />
            <Route element={<OurTeam />} path="about/our_team" />


            <Route element={<Blog />} path="blog" />
            <Route element={<Contact />} path="contact" />

            <Route element={<Projects />} path="projects" />
            <Route element={<ProjectInfo />} path="project_info/:id" />

            <Route element={<MainPage purpose={'list_projects'} />} path="admin_panel/projects/my_projects" />
            <Route element={<MainPage purpose={'view_project'} />} path="admin_panel/project_view/:id" />
            <Route element={<MainPage purpose={'add_project'} />} path="admin_panel/add_project" />
            <Route element={<MainPage purpose={'edit_project'} />} path="admin_panel/project_edit/:id" />


            {PROFILE_TABS.map((tab, index) => (
              <Route key={index} element={<MainPage purpose='profile' />} path={`admin_panel/profile/${tab}`} />
            ))}


            <Route element={<MainPage purpose={'requests'} />} path="admin_panel/requests" />
            <Route element={<MainPage purpose={'confirmation'} />} path="admin_panel/projects/confirmation_projects" />


            <Route element={<MainPage purpose={'price_list'} />} path="admin_panel/pricing" />

            <Route element={<EmailConfirm />} path="email-confirmed" />



            <Route element={<NotFound />} path="*" />

          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
