import { Grid, Typography, TextField, FormControl, Autocomplete } from "@mui/material";
import { useStyles } from "../styles";
import { useFormContext, Controller } from "react-hook-form";
import { countries } from "../Personal/constants";
import FormError from '../../../../../components/FormError/FormError'
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../../../../../../features/admin_panel/adminPanelSlice";

const DATA = [
    { label: 'Company Name', name: 'name', md: 6, rows: 1, multiline: false, max_length: 128 },
    { label: 'Company Address', name: 'address', md: 6, rows: 1, multiline: false, max_length: 128 },
    { label: 'Tax Number', name: 'tax_number', md: 6, rows: 1, multiline: false, max_length: 64 },
    { label: 'Tax Office', name: 'tax_office', md: 6, rows: 1, multiline: false, max_length: 64 },
    { label: 'About Company / Sector / Info', md: 12, name: 'about', rows: 3, multiline: true, max_length: 512 },
    { label: 'Company Website', name: 'website', md: 6, rows: 1, multiline: false, max_length: 64 },
    { label: 'Contact Email', name: 'contact_email', md: 6, rows: 1, multiline: false, max_length: 64 },
    { label: 'Zip Code', name: 'zip_code', md: 6, type: 'number', rows: 1, multiline: false },
]

export default function Company() {
    const { register, control, formState: { errors } } = useFormContext()
    const userInfo = useSelector(selectUserInfo)
    const classes = useStyles()
    return (
        <Grid container item xs={12} className={classes.root} spacing={2}>
            {DATA.map((el, index) => (
                <Grid item xs={12} md={el.md} sx={{ 'position': 'relative' }} key={index}>
                    <Typography>{el.label}</Typography>
                    <TextField fullWidth placeholder={el.label}
                        rows={el.rows}
                        multiline={el.multiline}
                        type={el?.type ? el.type : 'text'}
                        variant="outlined" size='small'
                        defaultValue={userInfo.company?.[el.name]}
                        {...register(el.name,
                            {
                                ...el?.type === 'number' && {
                                    min: {
                                        value: 0,
                                        message: 'The value must be greater than or equal to 0!'
                                    }
                                },

                                ...el.name === 'contact_email' && {
                                    pattern: {
                                        message: 'Please enter valid email!',
                                        value: /^((([0-9A-Za-z]{1}[-0-9A-z\.]{1,}[0-9A-Za-z]{1})|([0-9А-Яа-я]{1}[-0-9А-я\.]{1,}[0-9А-Яа-я]{1}))@([-0-9A-Za-z]{1,}\.){1,2}[-A-Za-z]{2,})$/
                                    }
                                },

                                ...el.max_length && {
                                    maxLength: {
                                        value: el.max_length,
                                        message: `The value must not exceed ${el.max_length} characters.`
                                    }
                                }
                            }
                        )}
                    />
                    <FormError error={errors[el.name]} errors={errors} name={el.name} />
                </Grid>
            ))}
            <Grid item xs={12} md={6}>
                <FormControl sx={{ width: '99%' }} size="small">
                    <Typography>Company Country</Typography>
                    <Controller
                        name={'country'}
                        defaultValue={userInfo?.company?.country}
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                defaultValue={countries.find(el => el?.label === userInfo?.company?.country)}
                                label='Company Country'
                                disablePortal
                                id="combo-box-demo"
                                size="small"
                                options={countries}
                                required
                                getOptionLabel={(option) => option?.label}
                                onChange={(e, v) => {
                                    v?.code && field.onChange(v.label)
                                }}
                                renderInput={(params) => <TextField  {...params} placeholder='Company Country' />}
                            />
                        )}
                    />
                </FormControl>
            </Grid>
        </Grid>
    )
}