import { Grid, Typography, Button, Collapse } from '@mui/material'
import CantProcessTrans from '../../components/CantProcessTrans/CantProcessTrans';
import CustomFilter from './CustomFilter/CustomFilter';
import { useEffect, useState, useRef } from 'react';
import { useStyles } from '../styles';
import { RealtorTariffs } from './constants';
import PackageCard from './PackageCard';
import { create_checkout } from '../../../../api/lemon_api';


export default function RealtorPriceList({ user }) {
    const classes = useStyles()
    const scrollableRef = useRef(null);

    const [amountAds, setAmountAds] = useState({ label: 'Can Add Ads (for All Estate Types)', value: 'undefined', active: false })
    const [validTimePeriod, setValidTimePeriod] = useState({ label: '', value: 'undefined', active: false })
    const [highlightQty, setHighlightQty] = useState({ label: 'Highlight - Promotion (0 Use) Ad', value: 'undefined', active: false })
    const [support, setSupport] = useState({ label: 'Customer and Target Market Analysis', value: false, active: false })

    const [price, setPrice] = useState(0)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        let price_amount = 0
        let price_for_one_ad = validTimePeriod.value === 1 || validTimePeriod.value === 'undefined' ? 5.0 : 4.0

        if (amountAds.value !== 'undefined' && amountAds.value >= 20) {
            price_for_one_ad = 3.60
        }

        amountAds.value !== 'undefined' && (price_amount += (Number(amountAds.value) * price_for_one_ad))
        validTimePeriod.value !== 'undefined' && (price_amount *= Number(validTimePeriod?.value))
        highlightQty.value !== 'undefined' && (price_amount += (Number(highlightQty?.value) * 10))
        support.value !== false && (price_amount += Number(30))

        setPrice(Math.round(price_amount - 1))
    }, [amountAds, validTimePeriod, highlightQty, support])

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                if (scrollableRef.current && open) {
                    scrollableRef.current.scrollTop = 0;
                }
            }, 1000)

            setTimeout(() => {
                open && setOpen(false)
            }, 5000)
        }
    }, [open])


    const updateTariff = async (label, price) => {
        // if (user.tariffs.length > 0) {
        //     setOpen(true)
        // }
        // else {
        //     let product = {'name': label}
        //     let checkout = {'email': user?.email, 'name': `${user?.first_name} ${user?.last_name}`}
        //     let custom = {'user_id': `${user?.pk}`}
        //     await create_checkout(price*100, product, checkout, custom).then((url) => {
        //         window.LemonSqueezy.Url.Open(url)
        //     })
        // }
    };

    return (
        <Grid container p={3} className={classes.root} alignContent={'baseline'} ref={scrollableRef}>

            <Grid container pt={1} pl={0} >
                <Typography color={'white'} variant='h4'>
                    Packages for Realtors
                </Typography>
            </Grid>

            <Grid container pl={0} mb={1}>
                <Typography color={'white'} variant='body2'>
                    You can choose the package you want and publish your ads or you can create your own individual package with different options you want and find the plan that suits you best. When choosing the most suitable package for you, pay attention to the package contents and valid usage periods.
                </Typography>
            </Grid>

            <CustomFilter setAmountAds={setAmountAds} setValidTimePeriod={setValidTimePeriod}
                setHighlightQty={setHighlightQty} setSupport={setSupport}
            />

<Grid container p={0} justifyContent={'center'}>

            <Collapse in={open} timeout={1000}>
                <CantProcessTrans />
            </Collapse>
            </Grid>

            <Grid container p={0} justifyContent={'center'} mt={2}>
                <Grid container item xs={12} md={6} lg={4} xl={3} p={1} mt={{ xs: 2, md: 2 }} alignSelf={'stretch'} >
                    <PackageCard properties={[amountAds, highlightQty, support]} price={price} validTimePeriod={validTimePeriod} />
                    <Grid item xs={12} align={'center'} mt={2}>
                        <Button variant='contained' className={!amountAds?.value && classes.btnPurchaseDisabled}
                            disabled={(amountAds?.value === 'undefined' || validTimePeriod?.value === 'undefined') && true} onClick={() => setOpen(true)} >
                            Purchase
                        </Button>
                    </Grid>
                </Grid>

                {RealtorTariffs.map((el, index) => (
                    <Grid container item xs={12} md={6} lg={4} xl={3} p={1} mt={{ xs: 2, md: 2 }} alignSelf={'stretch'} key={index}>
                        <PackageCard properties={el.properties} price={el.price} description={el.description} label={el.label} />
                        <Grid item xs={12} align={'center'} mt={2}>
                            <Button variant='contained' onClick={() => updateTariff(el.label, el.price)}>
                                Purchase
                            </Button>
                        </Grid>
                    </Grid>
                ))
                }
            </Grid>
        </Grid>
    )
}