
import { Grid, Paper, Avatar, Typography, Button, Rating, LinearProgress, Box } from '@mui/material'
import PinDropIcon from '@mui/icons-material/PinDrop';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import StarPurple500OutlinedIcon from '@mui/icons-material/StarPurple500Outlined';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './styles';


function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

const get_user_data = (user_info) => {
    const data = [
        { label: 'Country', value: user_info?.country },
        { label: 'Company', value: user_info?.company?.name },
        { label: 'Website', value: user_info?.company?.website },
        { label: 'Contact', value: user_info?.email },
        { label: 'Zip Code', value: user_info?.company?.zip_code },
    ]

    data.forEach(el => {
        !el.value && (el.value = 'empty')
    });

    return data
}

export default function Profile({ userInfo }) {
    const classes = useStyles()
    const navigate = useNavigate()
    const matches = useMediaQuery('(max-width:1200px)')

    return (
        <Grid container p={1} alignItems="flex-start" justifyContent={matches ? 'center' : 'space-between'} className={classes.root}>
            <Grid container item p={0} xs={12} lg={4} xl={3} sx={{ mb: { xs: 2, lg: 0 } }} maxWidth={'350px !important'}
                component={Paper} className={classes.cardBlock} justifyContent='center' alignSelf={'stretch'}>
                <Grid container item xs={12} justifyContent='space-between' alignItems='center' p={2} pt={0} className={classes.borderBottom}>
                    <Grid item >
                        <Avatar sx={{ width: 80, height: 80 }} src={userInfo?.avatar} variant="rounded" alt="Remy Sharp" />
                    </Grid>

                    <Grid container item p={2} flex={1}>
                        <Grid item xs={12}>
                            <Typography p={0} color='white'>{userInfo?.first_name} {userInfo?.last_name}</Typography>
                            <Typography variant='caption' color='gray' p={0}>
                                {userInfo?.have_access_like_investor && 'Investor'}
                                {userInfo?.have_access_like_realtor && 'Realtor'}
                                {!userInfo?.have_access_like_realtor && !userInfo?.have_access_like_investor && 'Admin'}
                            </Typography>
                        </Grid>

                        <Grid item >
                            <Button variant='outlined' size='small' onClick={() => navigate('/admin_panel/profile/packages')}>
                                My Packages
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent='space-between' className={classes.infoBlock} alignItems='center' pr={5} pt={2} pl={5}>
                    <Grid item >
                        <PinDropIcon />
                    </Grid>
                    <Grid item >
                        <Typography color='gray'>{userInfo?.company.address}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.lineBlock} mb={2}> </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent='space-between' className={classes.infoBlock} alignItems='center' pr={5} pl={5}>
                    <Grid item >
                        <EmailRoundedIcon />
                    </Grid>
                    <Grid item >
                        <Typography color='gray'>{userInfo?.email}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.lineBlock} mb={2}> </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent='space-between' className={[classes.infoBlock, classes.borderBottom].join(' ')} alignItems='center' pr={5} pl={5} >
                    <Grid item >
                        <LocalPhoneRoundedIcon />
                    </Grid>
                    <Grid item >
                        <Typography color='gray'>{userInfo?.phone_number}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.lineBlock} mb={2}> </Grid>
                </Grid>

                <Grid container item xs={10} justifyContent='center' className={classes.statisticBlock} alignItems='center' pr={2} pt={2} pl={2}>
                    <Grid item align='center' p={0} >
                        <Typography color='white' variant='h4'>{userInfo?.count_project}</Typography>
                        <Typography color='gray' variant='caption' margin={0}>Projects</Typography>
                    </Grid>
                </Grid>

                <Grid container item xs={3} justifyContent='center' alignItems='center' pr={2} pt={2} pl={2}>
                    <Grid item align='center' p={0} >
                        <Rating readOnly name="size-small" defaultValue={2} size="small"
                            emptyIcon={<StarPurple500OutlinedIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />
                        <Typography variant='caption' color='white'>Total Ratio</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container item p={0} lg={7.8} xl={8.8} alignSelf={'stretch'} alignContent={'space-between'}>
                <Grid container item p={0} xs={12} component={Paper} className={classes.cardBlock}>
                    <Grid item p={2} >
                        <Typography variant='h4' color='white'>About & Information</Typography>
                    </Grid>

                    <Grid item p={2} xs={12}>
                        <Typography variant='body1' color='white'>
                            {userInfo.about}
                        </Typography>
                    </Grid>

                    <Grid container p={1} mb={2}>
                        {get_user_data(userInfo).map((el, index) => (
                            <Grid container item pl={1} xs={12} key={index}>
                                <Grid item xs={4} lg={3}>{el.label}</Grid>
                                <Grid item xs={4} lg={3}> : </Grid>
                                <Grid item xs={4} lg={3}>{el.value}</Grid>
                            </Grid>
                        ))}
                    </Grid>

                    <Grid item xs={12} component={Paper}></Grid>
                </Grid>

                <Grid container item p={0} mt={2}>
                    <Grid container item p={2} xs={12} component={Paper} className={classes.cardBlock} justifyContent={'space-evenly'}>
                        {Object.keys(userInfo?.project_count_info).map((key, index) => (
                            <Grid item xs={12} md={5} m={1} key={index}>
                                <Typography>
                                    {key.replace('_count', '').replace('_and_', ' & ').replaceAll('_', ' ').split(' ').map(el => el[0].toUpperCase() + el.slice(1) + ' ')}
                                </Typography>
                                <LinearProgressWithLabel variant="determinate" value={userInfo?.project_count_info[key] === 0 ? 0 : userInfo?.project_count_info[key] * 100 / userInfo?.count_project} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}