import { Grid, Paper, Typography, FormGroup, FormControlLabel, Switch } from "@mui/material"
import { useStyles } from "./styles"

export default function GeneralSettings() {
    const classes = useStyles()

    return (
        <Grid container alignItems="flex-start" justifyContent='space-evenly' className={classes.root} p={1}>
            <Grid container item p={0} pt={2} pb={2} xs={12} sx={{ mb: { xs: 2, lg: 0 } }}
                component={Paper} className={classes.cardBlock} justifyContent='space-evenly' alignItems={'start'}>
                <Grid container mb={4}>
                    <Typography variant="h4">Settings/Configurations</Typography>
                </Grid>

                <Grid container mb={4}>
                    <FormGroup>
                        <FormControlLabel control={<Switch defaultChecked />} label="Dark Mode" />
                        <FormControlLabel required control={<Switch />} label="Notification Mails"/>
                    </FormGroup>
                </Grid>
            </Grid>
        </Grid>
    )
}