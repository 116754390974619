import { makeStyles } from "@mui/styles";
import { tokens } from '../../../theme'
import { useTheme } from '@mui/material'

export const useStyles = makeStyles(() => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    return ({
        root: {
            height: '100%'
        },

        registrationWrap: {
            background: colors.black.DEFAULT,
            borderRadius: '8px',
            boxShadow: `1px 1px 5px 2px ${colors.black.DEFAULT}`,
        },

        form: {
            width: '85%',
            '& span':{
                cursor: 'pointer',
                textDecoration: 'underline'
            }
        }

    })
})