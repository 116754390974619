import { Grid, Collapse } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectAllProjects, selectStatusProjects, fetchProjects, selectProjectFilters } from "../../../../features/projects/projectsSlice";
import { useEffect, useState } from "react";
import SearchPanel from '../components/SearchPanel/SearchPanel'
import { useNavigate } from "react-router-dom";
import ProjectCard from '../components/ProjectCard/ProjectCard';
import { setProjectId, setTariffId } from '../../../../features/projects/projectsSlice';
import FilterBlog from '../components/FilterBlog/FilterBlog';
import ChoosePackage from "../../components/ChoosePackageDialog/ChoosePackage";
import { useStyles } from "./styles";
import Loader from "../../../components/Loader/Loader";
import PaginatorAdvert from "../components/Paginator/PaginatorAdvert";

export default function ListAdminPanelProjects() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const filters = useSelector(selectProjectFilters)
    const status = useSelector(selectStatusProjects)
    const [projects, setProjects] = useState(null)
    const ft_projects = useSelector(selectAllProjects)
    const [filter, setFilter] = useState(false)

    const [chooseAdDialog, setChooseAdDialog] = useState(false)
    const [chosenTariffId, setChosenTariffId] = useState(null)

    const [loader, setLoader] = useState(true)

    const classes = useStyles({ filter })
    const [search, setSearch] = useState(false)

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchProjects({})).then(() => {
                setLoader(false)
            })
        }
        else {
            setLoader(false)
        }
    }, [])

    useEffect(() => {
        setProjects(ft_projects)
    }, [ft_projects, dispatch])

    useEffect(() => {
        search && search !== '' 
            ? dispatch(fetchProjects({ filters: filters, search: search }))
            : search === '' && dispatch(fetchProjects({ filters: filters }))
    }, [search])

    const selectProject = (id) => {
        dispatch(setProjectId({ project_id: id }))
        navigate(`/admin_panel/project_view/${id}/`)
    }

    const navigateToAddProjectPage = () => {
        dispatch(setTariffId({ tariffId: chosenTariffId }))
        navigate(`/admin_panel/add_project/`)
    }

    return (
        loader
            ? <Loader />
            : <Grid container p={1} className={classes.root} alignContent={'items'} flexDirection={'column'} flexWrap={'nowrap'}>
                <Grid container p={0}>
                    <SearchPanel setSearch={setSearch} filter={filter} setFilter={setFilter} setChooseAdDialog={setChooseAdDialog} />
                </Grid>
                <Grid container item p={0} mb={1}>
                    <Collapse in={filter} timeout={800}>
                        <FilterBlog />
                    </Collapse>
                </Grid>

                <Grid container p={0}>
                    {projects === null
                        ? <Grid container></Grid>
                        : (
                            <Grid container p={0} justifyContent='flex-start'
                                className={projects?.length === 0 && classes.noProjects}>
                                <Grid container p={0} spacing={2} >
                                    {projects?.map((project) => {
                                        return (
                                            <Grid container p={0} item xs={12} sm={6} lg={4} xl={3} onClick={() => selectProject(project.id)} sx={{ cursor: 'pointer' }} alignSelf={'normal'} justifyContent={'center'}>
                                                <ProjectCard content={project} location={project.location}
                                                    image_preview={project.preview_image} details={project.details}
                                                />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        )
                    }
                </Grid>

                <Grid container p={0} mt={1} justifyContent={'center'} flex={'auto'} pb={1}>
                    <PaginatorAdvert setLoader={setLoader} />
                </Grid>

                <ChoosePackage isOpen={chooseAdDialog} setIsOpen={setChooseAdDialog} titleKey='add_advert'
                    successFunc={navigateToAddProjectPage} chosenTariffId={chosenTariffId} setChosenTariffId={setChosenTariffId} />
            </Grid>
    )
}