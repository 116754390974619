import { useEffect, useState } from "react";
import { Grid, Link } from "@mui/material";
import logo from "../../../assets/images/basic/logo.png"
import backgroundImage from '../../../assets/images/registration/background.jpg'
import SignUp from "./SignUp/sign_up";
import SignIn from "./SignIn/sign_in";
import RecoveryPassword from "./RecoveryPassword/RecoveryPassword";
import RecoveryFromEmail from "./RecoveryFromEmail/RecoveryFromEmail";
import Loader from "../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./styles";


export default function RegistrationPage({ purpose }) {
    const classes = useStyles()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(true)
    const [bgImage, setBgImage] = useState('');

    useEffect(() => {
        let img = new Image();
        img.src = backgroundImage
        img.onload = () => {
            setBgImage(backgroundImage);
            setLoader(false);
        };
    }, [])

    const move_to_home = () => {
        navigate('/')
    }

    return (
        loader
            ? <Loader />
            :
            <Grid container className={classes.root} alignContent="center" sx={{ backgroundImage: `url(${bgImage})` }} justifyContent="center" p={{xs:2, md:1}}>
                <Grid container item xs={12} p={0} justifyContent={'center'} >
                    <Link href='/' onClick={move_to_home}>
                        <img src={logo} width='350px' height={'100px'} alt="logo" />
                    </Link>
                </Grid>
                <Grid container item xs={12} md={8} lg={6} xl={4} p={0} mb={5} mt={3} mr={0} className={classes.registrationWrap}>
                    <Grid container p={0} justifyContent='center' mt={2} >
                        {purpose === 'sign_up' && <SignUp />}
                        {purpose === 'sign_in' && <SignIn />}
                        {purpose === 'recovery_password' && <RecoveryPassword />}
                        {purpose === 'recovery_password_from_email' && <RecoveryFromEmail />}
                    </Grid>
                </Grid>
            </Grid>
    )
}