import { Grid, TextField, Typography, FormControl, Autocomplete, Input } from "@mui/material"
import { useFormContext, Controller } from "react-hook-form"
import { selectUserInfo } from "../../../../../../../features/admin_panel/adminPanelSlice"
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input/input'
import { useSelector } from "react-redux"
import { useStyles } from "../styles"
import { useState } from "react"
import { countries } from "./constants"
import React from "react"
import FormError from "../../../../../components/FormError/FormError"

const DATA = [
    { label: 'First Name', name: 'first_name', md: 6, rows: 1, multiline: false, max_length: 150 },
    { label: 'Last Name', name: 'last_name', md: 6, rows: 1, multiline: false, max_length: 150 },
    { label: 'Address', name: 'address', md: 6, rows: 1, multiline: false, max_length: 128 },
    { label: 'Tax Number', name: 'tax_number', md: 6, rows: 1, multiline: false, max_length: 32 },
    { label: 'About / Info', name: 'about', md: 12, rows: 3, multiline: true, max_length: 512 }
]

const PhoneInputCustom = React.forwardRef(function PhoneInputCustom(props, ref) {
    const { onChange, value, country, ...other } = props
    return (
        <PhoneInput
            {...other}
            withCountryCallingCode
            international
            country={country ? country : 'TR'}
            value={value}
            onChange={(value) => onChange({ target: { name: props.name, value } })}
        />
    )
})

export default function Personal() {
    const { register, control, formState: { errors } } = useFormContext()
    const userInfo = useSelector(selectUserInfo)
    const [phoneValue, setPhoneValue] = useState(userInfo?.phone_number)
    const [country, setCountry] = useState(parsePhoneNumber(userInfo?.phone_number)?.country)
    const classes = useStyles()

    return (
        <Grid container item xs={12} className={classes.root} spacing={1}>
            {DATA.map((el, index) => (
                <Grid item xs={12} md={el.md} key={index}>
                    <Typography>{el.label}</Typography>
                    <TextField fullWidth rows={el.rows} multiline={el.multiline} placeholder={el.label} defaultValue={userInfo?.[el.name]} variant="outlined" size='small'
                        {...register(el.name,
                            {
                                maxLength: {
                                    value: el.max_length,
                                    message: `The value must not exceed ${el.max_length} characters.`
                                }
                            }
                        )}
                    />
                    <FormError error={errors?.[el.name]} errors={errors} name={el.name} />
                </Grid>
            ))}
            <Grid item xs={12} md={6}>
                <FormControl sx={{ width: '99%' }} size="small">
                    <Typography>Country</Typography>
                    <Controller
                        name={'country'}
                        defaultValue={userInfo?.country}
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                defaultValue={countries.find(el => el.label === userInfo?.country)}
                                label='country'
                                disablePortal
                                id="combo-box-demo"
                                size="small"
                                options={countries}
                                required
                                getOptionLabel={(option) => option?.label}
                                onChange={(e, v) => {
                                    v?.code && field.onChange(v?.label)
                                    v?.code && setCountry(v?.code)
                                }}
                                renderInput={(params) => <TextField  {...params} placeholder='Country' />}
                            />
                        )}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} md={6} className={classes.phoneInput}>
                <Typography mb={.5}>Phone Number</Typography>
                <Input
                    {...register('phone_number', { setValueAs: v => parsePhoneNumber(v)?.number })}
                    disableUnderline={true}
                    fullWidth
                    size="small"
                    inputComponent={PhoneInputCustom}
                    inputProps={{
                        value: phoneValue,
                        onChange: (e) => setPhoneValue(e.target.value),
                        country: country
                    }}
                />
            </Grid>
        </Grid>
    )
}