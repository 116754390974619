import {
    Grid, Typography, TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox
} from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import CropSquareRoundedIcon from '@mui/icons-material/CropSquareRounded';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import FormError from '../../../../../components/FormError/FormError'
import { NumericFormat } from 'react-number-format';
import { is_english_text } from '../../../../../../../validators/file_validators';
import { selectUserInfo } from '../../../../../../../features/admin_panel/adminPanelSlice';
import { useSelector } from 'react-redux';

const NumericFormatCustom = React.forwardRef(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}

                thousandSeparator="."
                decimalSeparator=','
                valueIsNumericString
                onValueChange={(values) => {
                    const { value: numericValue } = values;
                    const cleanedValue = parseInt(numericValue.replace(/\./g, ''));
                    onChange({
                        target: {
                            name: props.name,
                            value: cleanedValue,
                        },
                    });

                }}
            />
        );
    },
);

export default function Advert({ project }) {
    const { register, watch, formState: { errors }, control } = useFormContext()
    const user = useSelector(selectUserInfo)

    const category = watch('general.estate_category')
    const type_estate = watch('general.estate_type')
    const flat_for_basis = watch('details.flat_for_land_basis')

    return (
        <Grid container item xs={12} lg={4.5} xl={4.5} spacing={2} sx={{ p: { xs: 0, md: 1.5 } }}
            className={!category && 'not_able_block'} alignItems={'center'}>
            <Grid item xs={12}>
                <Typography variant='h5' color='#fff'>Add Your Advert General Information</Typography>
            </Grid>
            <Grid item xs={12} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    size='small'
                    fullWidth
                    defaultValue={project?.name}
                    {...register('general.name', {
                        required: true,
                        validate: {
                            is_english: v => is_english_text(v) || 'You have to use only English'
                        }
                    })}
                    label={<React.Fragment>Advert / Project title<span>*</span></React.Fragment>}
                />
                <FormError error={errors?.general?.name} errors={errors.general} name={'name'} />
            </Grid>

            <Grid item xs={12} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    multiline
                    minRows={4}
                    size='small'
                    fullWidth
                    defaultValue={project?.description}
                    {...register('general.description', {
                        required: true,
                        validate: {
                            is_english: v => is_english_text(v) || 'You have to use only English'
                        }
                    })}
                    label={<React.Fragment>Description<span>*</span></React.Fragment>}
                />
                <FormError error={errors?.general?.description} errors={errors.general} name={'description'} />
            </Grid>

            {category === 'land_and_terrain' &&
                <Grid item xs={4} mt={2} className='advert_field'>
                    <FormControlLabel
                        size='small'
                        className='tab_text_field checkbox_field form_checkbox'
                        control={
                            <React.Fragment >
                                <Controller
                                    name={"details.flat_for_land_basis"}
                                    defaultValue={project?.details?.flat_for_land_basis}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Checkbox
                                            icon={<CropSquareRoundedIcon />}
                                            checkedIcon={<SquareRoundedIcon />}
                                            checked={value}
                                            onChange={(e) => onChange(e.target.checked)}
                                        />
                                    )}
                                />
                            </React.Fragment>}
                        label="Flat for Land Basis"
                        labelPlacement="top"
                    />
                </Grid>
            }

            {category === 'land_and_terrain' &&
                <Grid item xs={12} md={8} className='advert_field'>
                    <TextField
                        className='tab_text_field'
                        size='small'
                        defaultValue={project?.details?.proportion}
                        {...register('details.proportion', {
                            validate: {
                                is_english: v => is_english_text(v) || 'You have to use only English'
                            }
                        })}
                        fullWidth
                        id="outlined-required"
                        label="(Flat for Land) Ratio/Proportion"
                    />
                    <FormError error={errors?.details?.proportion} errors={errors.details} name={'proportion'} />
                </Grid>
            }

            <Grid item xs={12} md={4} className='advert_field'>
                <FormControl sx={{ width: '99%' }} size="small" >
                    <InputLabel id="demo-simple-select-label">
                        {<React.Fragment>Currency {!flat_for_basis && <span>*</span>}</React.Fragment>}
                    </InputLabel>
                    <Controller
                        name={'general.currency'}
                        defaultValue={project?.currency}
                        control={control}
                        rules={{ required: !flat_for_basis ? true : false }}
                        render={({ field }) => (
                            <Select {...field}
                                className='tab_text_field'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label='Currency'
                            >
                                <MenuItem value={'EUR'}>EUR</MenuItem>
                                <MenuItem value={'USD'}>USD</MenuItem>
                            </Select>
                        )}
                    />
                </FormControl>
                <FormError error={errors?.general?.currency} errors={errors.general} name={'currency'} />
            </Grid>

            <Grid item xs={12} md={4} className='advert_field'>
                <Controller
                    name={'general.price'}
                    defaultValue={project?.price}
                    control={control}
                    rules={{
                        required: !flat_for_basis ? true : false,
                        valueAsNumber: true,
                        min: {
                            value: 0,
                            message: 'The value must be greater than or equal to 0!'
                        }
                    }}
                    render={({ field: { onChange, value } }) => (
                        <TextField
                            className='tab_text_field'
                            size='small'
                            value={value}
                            fullWidth
                            InputProps={{
                                inputComponent: NumericFormatCustom,
                            }}
                            onChange={(e) => onChange(e.target.value)}
                            label={<React.Fragment>Price{!flat_for_basis && <span>*</span>}</React.Fragment>}
                        />
                    )}
                />

                <FormError error={errors?.general?.price} errors={errors.general} name={'price'} />
            </Grid>

            <Grid item xs={12} md={4} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    size='small'
                    fullWidth
                    type='number'
                    defaultValue={project?.discount}
                    {...register('general.discount', {
                        valueAsNumber: true,
                        min: {
                            value: 1,
                            message: 'The value must be greater than 0!'
                        },
                        max: {
                            value: 100,
                            message: 'The value must not exceed 100!'
                        }
                    })}
                    label="Discount (%)"
                />
                <FormError error={errors?.general?.discount} errors={errors.general} name={'discount'} />
            </Grid>

            <Grid item xs={12} md={4} className='advert_field'>
                <FormControl sx={{ width: '99%' }} size="small">
                    <InputLabel id="demo-simple-select-label">
                        {<React.Fragment>Measure <span>*</span></React.Fragment>}
                    </InputLabel>
                    <Controller
                        name={'general.measure'}
                        defaultValue={project?.measure}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <Select {...field}
                                className='tab_text_field'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Measure"
                            >
                                <MenuItem value={'sq.m'}>sq.m</MenuItem>
                                <MenuItem value={'hec'}>hec</MenuItem>
                            </Select>
                        )}
                    />
                </FormControl>
                <FormError error={errors?.general?.measure} errors={errors.general} name={'measure'} />
            </Grid>

            <Grid item xs={12} md={4} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    type='number'
                    size='small'
                    fullWidth
                    defaultValue={project?.gross_size}
                    {...register('general.gross_size', {
                        required: true, valueAsNumber: true, min: {
                            value: 1,
                            message: 'The value must be greater than 0!'
                        }
                    })}
                    label={<React.Fragment>Size (Gross)<span>*</span></React.Fragment>}
                />
                <FormError error={errors?.general?.gross_size} errors={errors.general} name={'gross_size'} />
            </Grid>

            <Grid item xs={12} md={4} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    type='number'
                    size='small'
                    fullWidth
                    defaultValue={project?.usage_size}
                    {...register('general.usage_size', {
                        valueAsNumber: true, min: {
                            value: 1,
                            message: 'The value must be greater than 0!'
                        }
                    })}
                    label="Size (Usage)"
                />
                <FormError error={errors?.general?.usage_size} errors={errors.general} name={'usage_size'} />
            </Grid>

            <Grid item xs={12} md={7} className='advert_field'>
                <FormControl sx={{ width: '99%' }} size="small" >
                    <InputLabel id="demo-simple-select-label">
                        {<React.Fragment>{category === 'land_and_terrain' ? 'Zoning State' : 'Project State'} <span>*</span></React.Fragment>}
                    </InputLabel>
                    <Controller
                        name={'general.state'}
                        defaultValue={project?.state}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <Select {...field}
                                className='tab_text_field'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Project State"
                            >

                                {category !== 'land_and_terrain' && <MenuItem value={'complete_project'}>Completed Project</MenuItem>}
                                {category !== 'land_and_terrain' && <MenuItem value={'under_construction'}>Under Construction</MenuItem>}
                                {category !== 'land_and_terrain' && <MenuItem value={'tenanted_estate'}>Tenanted Estate</MenuItem>}
                                {category !== 'land_and_terrain' && <MenuItem value={'off_plan_property'}>Off Plan Property</MenuItem>}

                                {category === 'land_and_terrain' && (type_estate === "plot_for_sale" || type_estate === "ready_project")
                                    && <MenuItem value={'improved_land'}>Improved Land</MenuItem>}
                                {category === 'land_and_terrain' && (type_estate === "plot_for_sale" || type_estate === "ready_project")
                                    && <MenuItem value={'vacant_land'}>Vacant Land</MenuItem>}
                                {category === 'land_and_terrain' && (type_estate === "plot_for_sale" || type_estate === "ready_project")
                                    && <MenuItem value={'raw_land'}>Raw Land</MenuItem>}
                                {category === 'land_and_terrain' && (type_estate === "plot_for_sale" || type_estate === "ready_project")
                                    && <MenuItem value={'residential_zone'}>Residential Zone</MenuItem>}

                                {category === 'land_and_terrain' && type_estate === "farm_and_garden"
                                    && <MenuItem value={'farming_field'}>Farming Field</MenuItem>}
                                {category === 'land_and_terrain' && type_estate === "farm_and_garden"
                                    && <MenuItem value={'ranch_for_sale'}>Ranch for Sale</MenuItem>}
                                {category === 'land_and_terrain' && type_estate === "farm_and_garden"
                                    && <MenuItem value={'hobby_garden'}>Hobby Garden</MenuItem>}
                                {category === 'land_and_terrain' && type_estate === "farm_and_garden"
                                    && <MenuItem value={'warehouse'}>Warehouse</MenuItem>}
                                {category === 'land_and_terrain' && type_estate === "farm_and_garden"
                                    && <MenuItem value={'woodland_and_forest'}>Woodland & Forest</MenuItem>}
                                {category === 'land_and_terrain' && type_estate === "farm_and_garden"
                                    && <MenuItem value={'vineyard'}>Vineyard</MenuItem>}
                                {category === 'land_and_terrain' && type_estate === "farm_and_garden"
                                    && <MenuItem value={'olive_grove'}>Olive Grove</MenuItem>}

                            </Select>
                        )}
                    />
                </FormControl>
                <FormError error={errors?.general?.state} errors={errors.general} name={'state'} />
            </Grid>


            <Grid item xs={12} md={5} className='advert_field'>
                <FormControl sx={{ width: '99%' }} size="small" >
                    <InputLabel id="demo-simple-select-label">
                        {<React.Fragment>From Whom <span>*</span></React.Fragment>}
                    </InputLabel>
                    <Controller
                        name={'general.from_whom'}
                        defaultValue={project?.from_whom}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <Select {...field}
                                className='tab_text_field'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="From Whom"
                            >

                                <MenuItem value={'from_by_owner'}>From by Owner</MenuItem>
                                <MenuItem value={'from_by_real_estate'}>From by Real Estate Agency</MenuItem>
                                <MenuItem value={'from_by_middleman'}>From by Middleman</MenuItem>
                                <MenuItem value={'from_by_company'}>From by Company</MenuItem>
                            </Select>
                        )}
                    />
                </FormControl>
                <FormError error={errors?.general?.from_whom} errors={errors.general} name={'from_whom'} />
            </Grid>

            <Grid item xs={12} md={6} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    size='small'
                    fullWidth
                    defaultValue={project?.title_deed_status}
                    {...register('general.title_deed_status')}
                    label="Title Deed Status"
                />
            </Grid>


            <Grid item xs={12} md={6} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    size='small'
                    fullWidth
                    {...register('general.dues_fee')}
                    defaultValue={project?.dues_fee}
                    label="Dues Fee"
                />
            </Grid>

            {user?.is_staff &&
                <Grid container item md={10} lg={8} p={0}>
                    <Grid item align='flex-start' mt={2} xs={2}>
                        <FormControlLabel
                            size='small'
                            className='tab_text_field checkbox_field checkbox_is_urgent form_checkbox'
                            control={
                                <React.Fragment >
                                    <Controller
                                        defaultValue={project?.urgent_sale}
                                        name="general.urgent_sale"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <Checkbox
                                                icon={<CropSquareRoundedIcon />}
                                                checkedIcon={<SquareRoundedIcon />}
                                                checked={value}
                                                onChange={(e) => onChange(e.target.checked)}
                                            />
                                        )}
                                    />
                                </React.Fragment>}
                            label="Urgent Sale"
                            labelPlacement="top"
                        />
                    </Grid>

                    <Grid item className='urgent_caption' xs={10}>
                        <Typography >
                            <span>*</span> Check this option if your project is for urgent sale and needs to be sold quickly.
                        </Typography>
                    </Grid>
                </Grid>
            }

            <Grid item xs={12} md={6} lg={4} mt={2} className='advert_field'>
                <FormControlLabel
                    size='small'
                    className='tab_text_field checkbox_field form_checkbox'
                    control={
                        <React.Fragment >
                            <Controller
                                defaultValue={project?.suitable_for_loan}
                                name={"general.suitable_for_loan"}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Checkbox
                                        icon={<CropSquareRoundedIcon />}
                                        checkedIcon={<SquareRoundedIcon />}
                                        checked={value}
                                        onChange={(e) => onChange(e.target.checked)}
                                    />
                                )}
                            />
                        </React.Fragment>}
                    label="Suitable for Loan"
                    labelPlacement="top"
                />
            </Grid>

        </Grid>
    )
}